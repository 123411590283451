@import "variables";
@import "framework/framework";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/fontawesome";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/brands";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/solid";
@import "../../node_modules/owl.carousel/src/scss/owl.carousel";
@import "../../node_modules/magnific-popup/src/css/main";

html {
	background: $color-tan;
	font-family: orpheuspro, serif;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 1;
	text-transform: uppercase;

	@include desktop-sm-down {
		font-size: 15px;
	}

	@include tablet-down {
		font-size: 14px;
	}

	@include phone-down {
		font-size: 13px;
	}

	@include phone-sm-down {
		font-size: 12px;
	}
}

body {
	color: $color-black;
}

a {
	color: $color-black;

	@include hover-focus {
		color: $color-black;
	}
}

header {
	> nav {
		padding: rem(10) 0;

		.container {
			align-items: center;
			display: flex;
			flex-direction: row;
			flex-wrap: nowrap;
			justify-content: space-between;
			max-width: 1230px !important;

			@include tablet-down {
				display: block;
				text-align: center;
			}

			> h1 {
				flex: 0 0 auto;
				margin: 0;
				max-width: rem(360);

				@media screen and (max-width: 1150px){
					max-width: rem(300);
				}
				@include tablet-down {
					margin-bottom: rem(20);
					> img {
						margin: 0 auto;
					}
				}
			}

			> .nav-collapse {
				align-items: center;
				display: flex;
				flex-direction: row;
				flex-wrap: nowrap;
				justify-content: flex-end;

				@include tablet-down {
					display: block;
				}

				> .menu {
					flex: 0 1 auto;
					list-style: none;
					margin: 0;
					padding: 0;

					> li {
						display: inline-block;
						font-size: rem(18);
						letter-spacing: letter-spacing(100);
						text-indent: text-indent(100);
						@media screen and (max-width: 1150px){
							font-size: rem(16);
						}

						+ li {
							margin-left: rem(20);
						}

						> a {
							@include hover-focus {
								text-decoration: underline;
							}
						}
					}
				}

				> .socials {
					flex: 0 1 auto;
					list-style: none;
					margin: 0 0 0 rem(20);
					padding: 0;

					@include tablet-down {
						margin: rem(20) 0 0 0;
					}

					> li {
						display: inline-block;
						vertical-align: middle;

						+ li {
							margin-left: rem(8);
						}

						> a {
							opacity: 1;

							@include hover-focus {
								opacity: 0.8;
							}
						}

						svg{
							display: block;
							width: 1.25em;
							height: auto;
						}
					}
				}
			}
		}
	}

	> img {
		width: 100%;
		max-height: 90vh;
		object-fit: cover;
		object-position: 50% 30%;
	}
}

main {
	.bg {
		background: url('../img/bg/bg.png') no-repeat center top;
		background-size: 100% auto;
	}

	section {
		padding: rem(85) 0;

		.section-title {
			font-size: rem(58);
			font-weight: 400;
			letter-spacing: letter-spacing(200);
			margin: 0 0 rem(85) 0;
			text-align: center;
			text-indent: text-indent(200);
		}

		.owl-carousel {
			padding: 0 rem(80);

			@include tablet-down {
				padding: 0 rem(40);
			}

			.owl-nav {
				> button {
					font-size: rem(25);
					opacity: 1;
					position: absolute;
					top: 50%;
					transform: translateY(-50%);

					@include hover-focus {
						opacity: 0.8;
					}

					&.owl-prev {
						left: 0;
					}

					&.owl-next {
						right: 0;
					}
				}
			}
		}

		&#tunespeak {
			text-align: center;

			p {
				font-size: rem(16);
				line-height: rem(28, 16);
				margin: rem(50) 0 0 0;
			}

			a {
				border: 1px solid $color-black;
				display: inline-block;
				font-size: rem(20);
				margin-top: rem(50);
				padding: rem(15) rem(30);
				vertical-align: middle;

				@include hover-focus {
					background: $color-black;
					color: $color-tan;
				}
			}
		}

		&#music {
			padding: 0;

			.owl-carousel {
				.item-music {
					margin: rem(60) 0 0 0;
					padding: 0 rem(25);
					text-align: center;

					img {
						width: auto;
					}

					> a {
						display: inline-block;
					}

					.cover {
						box-shadow: -4px -4px 25px 0px rgba(0, 0, 0, 0.20);
						margin: 0 auto;
						width: rem(675);
					}

					span {
						display: block;
						font-size: rem(20);
						letter-spacing: letter-spacing(400);
						margin: rem(35) 0 rem(25) 0;
						text-indent: text-indent(400);
					}

					.buy {
						list-style: none;
						margin: 0;
						padding: 0;

						> li {
							display: inline-block;
							padding: rem(5);
							vertical-align: middle;

							> a {
								opacity: 1;

								@include hover-focus {
									opacity: 0.8;
								}

								&.text-link {
									border: 1px solid $color-brown;
									color: $color-brown;
									display: inline-block;
									font-size: rem(24);
									margin-top: rem(5);
									padding: rem(10) rem(10);
									text-align: center;
									vertical-align: middle;

									@include hover-focus {
										background: $color-brown;
										color: $color-tan;
										opacity: 1;
									}
								}
							}
						}
					}
				}
			}
		}

		&#videos {
			padding: rem(180) 0 rem(140) 0;

			.section-title {
				margin-bottom: rem(50);
			}

			.owl-carousel {
				.owl-video-wrapper {
					overflow: hidden;
					padding-bottom: 56.25%;
					position: relative;

					iframe,
					.owl-video-tn {
						background-size: cover;
						bottom: 0;
						left: 0;
						position: absolute;
						right: 0;
						top: 0;
					}
				}

				.item-video {
					text-align: center;

					a {
						display: block;
						opacity: 1;

						@include hover-focus {
							opacity: 0.8;
						}
					}

					span {
						display: block;
						font-size: rem(18);
						letter-spacing: letter-spacing(200);
						margin-top: rem(20);
						text-indent: text-indent(200);
					}
				}
			}
		}

		&#tour {
			background: $color-brown;
			color: $color-white;
			text-align: center;

			#events {
				display: flex;
				flex-direction: row;
				flex-wrap: wrap;
				margin: 0 -15px;

				> div {
					flex: 0 0 50%;
					font-size: rem(20);
					letter-spacing: letter-spacing(400);
					line-height: line-height(30, 20);
					margin: 0 0 rem(30) 0;
					padding: 0 15px;
					text-indent: text-indent(400);

					@include tablet-down {
						flex: 0 0 100%;
						width: 100%;
					}

					&:nth-child(n+13) {
						display: none;
					}

					.rsvp {
						color: $color-white;
						text-decoration: underline;

						@include hover-focus {
							text-decoration: none;
						}
					}

					.btn {
						border: 1px solid $color-white;
						color: $color-white;
						display: inline-block;
						margin-top: rem(5);
						padding: rem(5) rem(10);
						text-align: center;
						vertical-align: middle;

						@include hover-focus {
							background: $color-white;
							color: $color-brown;
						}
					}
				}
			}

			#load-more-dates {
				color: $color-white;
				display: inline-block;
				font-size: rem(16);
				letter-spacing: letter-spacing(400);
				margin-top: rem(70);
				text-decoration: underline;
				text-indent: text-indent(400);
				vertical-align: middle;

				@include hover-focus {
					text-decoration: none;
				}
			}
		}

		&#endorsements {
			.endorsements {
				display: flex;
				flex-direction: row;
				flex-wrap: wrap;
				margin: 0 -15px;

				> div {
					flex: 0 0 25%;
					margin-bottom: 30px;
					padding: 0 15px;

					@include phone-down {
						flex: 0 0 50%;
					}
				}
			}
		}
	}
}

footer {
	padding: rem(50) 0;
	text-align: center;

	.endorsements-link {
		display: inline-block;
		letter-spacing: letter-spacing(200);
		line-height: line-height(22, 12);
		margin: 0 0 rem(30) 0;
		text-decoration: underline;
		text-indent: text-indent(200);
		vertical-align: middle;

		@include hover-focus {
			text-decoration: none;
		}
	}

	.management {
		font-size: rem(12);
		letter-spacing: letter-spacing(200);
		line-height: line-height(22, 12);
		margin: 0 0 rem(30) 0;
		text-indent: text-indent(200);

		a {
			text-decoration: underline;

			@include hover-focus {
				text-decoration: none;
			}
		}
	}

	.copyright {
		> a {
			display: inline-block;
			margin-bottom: rem(20);
			max-width: rem(120);
			opacity: 1;
			vertical-align: middle;

			@include hover-focus {
				opacity: 0.8;
			}
		}

		> p {
			font-size: rem(12);
			letter-spacing: letter-spacing(200);
			line-height: line-height(22, 12);
			margin: 0;
			text-indent: text-indent(200);
		}
	}
}

.popup {
	background: $color-tan;
	line-height: 1.5;
	margin: 20px auto;
	max-width: 800px;
	padding: rem(30);
	position: relative;
	text-align: center;
	text-transform: none;
	width: auto;

	p {
		margin: 0;

		+ p {
			margin-top: rem(20);
		}
	}
}
#tour-banner{
	max-width: 840px;
	background-color: #927962;
	padding: 0;
	display: flex;
	align-items: center;
	position: relative;
	>div{
		flex: 1;
		&:nth-child(2){
			padding: rem(30);
		}
	}
	h2{
		margin: 0;
		font-size: rem(48);
		span{
			display: inline-block;
			font-size: .6em;
			margin: 0;
		}
	}
	@media screen and (max-width: 800px){
		flex-direction: column;
		max-width: 400px;
		width: 100%;
	}
}
#tour-banner .mfp-close {
	color: $color-white;
}

#tour-banner .btn {
	background: $color-tan;
	color: #271f1e;
	display: inline-block;
	margin-top: rem(25);
	padding: rem(5) rem(10);
	font-size: rem(20);
	text-align: center;
	vertical-align: middle;

	@include hover-focus {
		background: #271f1e;
		color: $color-tan;
	}
}

#movie-modal{
	border: 15px #927e65 solid;
	h2{
		margin-top: 0;
	}
	h2, h3{
		font-weight: normal;
		text-transform: uppercase;
		margin: .5em 0;
		letter-spacing: .1em;
	}

	.btn {
		background: #271f1e;
		color: $color-tan;
		display: inline-block;
		padding: rem(5) rem(20);
		font-size: rem(20);
		margin: rem(8); 
		text-align: center;
		vertical-align: middle;

		@include hover-focus {
			background: $color-tan;
			color: #271f1e;
		}
	}
	@media screen and (max-width: 700px){
		border-width: 10px;
		padding: 15px;
	}
}

#movie-banner{
	background: none;
	width: 100%;
	max-width: rem(1100);
	display: flex;
	@media screen and (max-width: 700px){
		flex-direction: column;
		max-width: rem(400);
	}
	img{
		display: block;
	}
	a{
		@include hover-focus{
			opacity: .8;
		}
	}
	.mfp-close {
		color: #fff;
		width: 30px;
		height: 30px;
	}
}